import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import CustomBreadcrumb from '../../components/CustomBreadcrumb';

const UsersGuide = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	return (
		<Layout>
			<Seo
				title="Horizon Hearing Aids User Guides"
				description="Unlock the full potential of your Horizon hearing aids with our comprehensive user guides, offering detailed instructions, expert tips, and troubleshooting advice."
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5">
					<section className="c-content-hero">
						<div className="o-row-2p5">
							<img
								className="o-fluid-img"
								src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/user-guide-hero.jpg"
								alt="woman holding hearing aids"
							/>
						</div>
						<div className="o-row-2p5">
							<h1 className="c-users-guide__headline">Horizon Hearing Aids User Guides</h1>
						</div>
						<div className="c-content-hero__divider"></div>
					</section>
				</div>
				<div className="o-row-3p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="o-row-3p5">
					<p className="c-users-guide__copy">
						Welcome to the comprehensive library of user guides for the Horizon series of hearing aids by hear.com.
						Whether you're a first-time user or a seasoned wearer, these guides are crafted to empower you with the
						knowledge and confidence to make the most of your hearing aid experience.
					</p>
				</div>
				<div className="o-row-3p5">
					<p className="c-users-guide__copy">
						Below, you'll find a visual representation of the different Horizon models. Simply locate your specific
						model, click on it, and access the corresponding user guide in PDF format. Whether you prefer digital access
						or need a hard copy for reference, our user guides are readily available for your convenience.
					</p>
				</div>
				<div className="o-row-3p5">
					<p className="c-users-guide__copy">
						Explore our collection to discover detailed instructions, tips, and troubleshooting advice, ensuring that
						you can enjoy clear, vibrant sound in every aspect of your life.
					</p>
				</div>
				<div className="o-row-3p5">
					<p className="c-users-guide__title">Browse User Guide By Product</p>
				</div>
			</div>
			<div className="c-users-guide-background">
				<div className="o-wrapper">
					<div className="o-row-4">
						<div className="c-users-guide-container">
							<div className="c-users-guide-box">
								<div className="o-row-3p5">
									<div className="c-users-guide-box__img">
										<img
											className="c-users-guide-box__img"
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-go-ix.png"
											alt="hearing aids"
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__title">
										<p>Horizon Go IX (RIC)</p>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__content-logo">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/file.svg"
											alt=""
										/>
									</div>
								</div>
								<a href="https://www.hear.com/horizon-go-ix-user-guide.pdf" target="_blank" rel="noopener noreferrer" class="c-users-guide-box__btn">
									User Guide
								</a>
							</div>
							<div className="c-users-guide-box">
								<div className="o-row-3p5">
									<div className="c-users-guide-box__img">
										<img
											className="c-users-guide-box__img c-users-guide-box__img--mini"
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-mini-ix.png"
											alt="hearing aids"
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__title">
										<p>Horizon Mini IX (CIC)</p>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__content-logo">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/file.svg"
											alt=""
										/>
									</div>
								</div>
								<a href="https://www.hear.com/horizon-mini-ix-user-guide.pdf" target="_blank" rel="noopener noreferrer" className="c-users-guide-box__btn">
									User Guide
								</a>
							</div>
						</div>
					</div>
					<div className="o-row-4">
						<div className="c-users-guide-container">
							<div className="c-users-guide-box">
								<div className="o-row-3p5">
									<div className="c-users-guide-box__img">
										<img
											className="c-users-guide-box__img"
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-go-x-ric.png"
											alt="hearing aids"
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__title">
										<p>Horizon Go AX (RIC)</p>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__content-logo">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/file.svg"
											alt=""
										/>
									</div>
								</div>
								<a href="https://www.hear.com/horizon-go-ax-user-guide.pdf" target="_blank" rel="noopener noreferrer" class="c-users-guide-box__btn">
									User Guide
								</a>
							</div>
							<div className="c-users-guide-box">
								<div className="o-row-3p5">
									<div className="c-users-guide-box__img">
										<img
											className="c-users-guide-box__img c-users-guide-box__img--mini"
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-go-x-cic.png"
											alt="hearing aids"
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__title">
										<p>Horizon Mini X (CIC)</p>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__content-logo">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/file.svg"
											alt=""
										/>
									</div>
								</div>
								<a href="https://www.hear.com/horizon-mini-x-user-guide.pdf" target="_blank" rel="noopener noreferrer" className="c-users-guide-box__btn">
									User Guide
								</a>
							</div>
						</div>
					</div>
					<div className="o-row-4">
						<div className="c-users-guide-container">
							<div className="c-users-guide-box">
								<div className="o-row-3p5">
									<div className="c-users-guide-box__img">
										<img
											className="c-users-guide-box__img"
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-go-x-ric.png"
											alt="hearing aids"
										/>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__title">
										<p>Horizon Go X (RIC)</p>
									</div>
								</div>
								<div className="o-row-2">
									<div className="c-users-guide-box__content-logo">
										<img
											src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/file.svg"
											alt=""
										/>
									</div>
								</div>
								<a href="https://www.hear.com/horizon-go-x-user-guide.pdf" target="_blank" rel="noopener noreferrer" class="c-users-guide-box__btn">
									User Guide
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default UsersGuide;
